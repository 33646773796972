/* https://coolors.co/000505-28c2ff-fbfbff-8338ec-d65108 */
$rich-black-fogra-39: #000505ff;
$capri: #28c2ffff;
$ghost-white: #fbfbffff;
$blue-violet: #8338ecff;
$burnt-orange: #d65108ff;

// https://coolors.co/000505-fbfbff-c9c8bb-8338ec-d65108
/* SCSS HEX */
$rich-black-fogra-39: #000505ff;
$ghost-white: #fbfbffff;
$timberwolf: #c9c8bbff;
$blue-violet: #8338ecff;
$burnt-orange: #d65108ff;

// Our variable overrides
$theme-colors: (
  "primary":    $blue-violet,
  "secondary":  $timberwolf,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
  "danger":     $burnt-orange,
  "light":      $ghost-white,
  "dark":       $rich-black-fogra-39
);

// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";


.spacer-row {
  margin-top: 3em;
  margin-bottom: 3em;
}

#episodes-list-podcast-selector > button, .list-page-header {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 40px;
}

#episodes-list-podcast-selector > button::after {
  font-size: 15px;
}

.App {
  // background-color: $light
}

.landing-feature-image {
}

.list-page-obj-summay-link-btn {
  color: $dark;
  padding-left: 0;
  font-size: 28px;
  font-weight: 500;
  text-decoration-line: none;
}

.audio-player {
  min-width: 275px;
}

.episode-editor-tag-dropdown > button {
  width: 100%;
  justify-content: end;
}

.episode-editor-marker-table-header {
  font-weight: bold;
}

.landing-price {
  text-align: center;
}

.how-to-card-header {
  background: $blue-violet;
  color: $ghost-white;
}